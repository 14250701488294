.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.image-container {
  width: 100%; /* Defina o tamanho máximo que você deseja para a imagem */
  height: 80vh; /* Defina a altura máxima que você deseja para a imagem */
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  width: 80vw;
  height: 70vh;
  /* max-width: 100%;
  max-height: 100%; */
  border: 3px solid #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  margin: 20px 0;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: default;
}
