.class-container {
  position: absolute;
  top: 20px;
  left: 80px;
  background: #c8cad3;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.class-close-btn {
  position: absolute;
  right: 16px;
  top: 20px;
  margin: 0px;
  background-color: transparent;
  color: #0043b5;
  border: none;
  padding: 0px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 1s;
}
.class-close-btn:hover {
  background-color: transparent;
  color: #0969ff;
}

.class-select {
  width: 100%;
}

.class-buttons {
  font-weight: 700;
}
