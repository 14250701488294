.actions-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #999;
  margin-left: 18px;
  padding: 10px 10px;
  border-radius: 6px;
}

.actions-container-button {
  padding: 0px;
  margin: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: transparent;
  /* background: #444; */
}

.actions-container-button:hover {
  background: #444;
}

.actions-container-button-active {
  background: #444;
}
